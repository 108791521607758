/* Estilos para el contenedor principal del dashboard */
.dashboard {
  background-color: #1e1e1e; /* Fondo oscuro */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Dos columnas en horizontal */
  gap: 20px; /* Espacio entre gráficos */
  padding: 20px; /* Espacio interior del dashboard */
  font-family: 'Roboto', sans-serif; /* Fuente personalizada */
  color: #fff; /* Color de texto blanco */
  /* Evito espacio entre componentes */
  margin: 0;
}

/* Estilos para cada gráfico */
.chart {
  padding: 20px;
  border: 1px solid #333; /* Borde oscuro */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra suave */
  background-color: #292929; /* Fondo gris oscuro */
  color: #fff; /* Color de texto blanco */
  width: 600px;
}

/* Estilos para el título de los gráficos */
.chart h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

/* Estilos para las tarjetas de valores */
.sensor-card {
  padding: 20px;
  border: 1px solid #333; /* Borde oscuro */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra suave */
  background-color: #292929; /* Fondo gris oscuro */
  color: #fff; /* Color de texto blanco */
  width: 400px;

}

/* Estilos para el título de las tarjetas */
.sensor-card h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

/* Estilos para los valores de fecha y valor */
.sensor-card p {
  font-size: 16px;
  margin: 10px 0;
  color: rgba(52, 152, 219, 1);
}

/* Estilos adicionales según tus preferencias */
