/* Estilos para el navbar */
.navbar {
    background-color: #014a12;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  /* Estilos para el título en el navbar */
  .navbar h2 {
    font-family: 'Roboto', sans-serif; /* Fuente personalizada */
    font-size: 24px;
    margin: 0;
    color: rgba(52, 152, 219, 1);
  }

  
  /* Estilos adicionales según tus preferencias */
  